<template>
  <div>
    <b-navbar wrapper-class="container is-max-widescreen" fixed-top transparent class="navbar-transparent" :class="{
      'active': !isTop,
      'hide-nav': hideNav,
      'is-home': isHome
    }">
      <template slot="brand">
        <b-navbar-item tag="router-link" to="/">
          <img
              :src="'/assets/img/logo-light.png'"
              alt="Sgara Logo">
        </b-navbar-item>
      </template>

      <template slot="end">
        <b-navbar-item :tag="nav.external_link ? 'a' : 'router-link'"
                       :href="nav.path" :to="nav.path" :active="path === nav.path"
                       v-for="(nav, key) in navItems" :key="key">
          {{ $t('navbar.' + nav.name) }}
        </b-navbar-item>
        <b-navbar-dropdown boxed>
          <template #label>
            <b-icon icon="translate" size="is-small" class="mr-2"></b-icon>
            {{ lang.toUpperCase() }}
          </template>
          <b-navbar-item @click="changeLocale('id')">
            Indonesia
          </b-navbar-item>
          <b-navbar-item @click="changeLocale('en')">
            English
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import navItems from "@/lib/navItems";
import {mapState} from 'vuex'

export default {
  name: "SgNavbar",
  data: () => {
    return {
      isTop: true,
      scrollY: 0,
      hideNav: false,
    }
  },
  computed: {
    path() {
      return this.$route.path
    },
    navItems() {
      return navItems
    },
    ...mapState([
      'lang'
    ]),
    isHome() {
      return this.$route.path === '/'
    },
  },
  watch: {
    '$route.path'() {
      this.handleScroll()
    }
  },
  created() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.isTop = window.scrollY === 0

      this.hideNav = window.scrollY > this.scrollY

      this.scrollY = window.scrollY
    },
    changeLocale(lang) {
      this.$store.dispatch('updateLocale', lang)
    },
  }
}
</script>
