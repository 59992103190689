<template>
  <div class="main">
    <sg-navbar/>

    <div>
      <div id="main" style="min-height: 60vh;">
        <!--      <div class="container is-max-widescreen" id="main" style="min-height: 60vh;">-->
        <router-view/>
      </div>
    </div>

    <sg-footer/>
  </div>
</template>

<script>
import SgNavbar from "@/components/Sg/SgNavbar";
import SgFooter from "@/components/Sg/SgFooter";

export default {
  name: 'Main',
  components: {
    SgFooter,
    SgNavbar,
  },
}
</script>
