export default [
  {
    name: 'home',
    path: '/',
  },
  {
    name: 'blog',
    path: 'https://sgara.id/blog/',
    external_link: true,
  },
  // {
  //   name: 'solutions',
  //   path: '/#solutions',
  //   custom_action: true,
  //   external_link: true,
  // },
  {
    name: 'contact',
    path: '/#contact-us',
    custom_action: true,
    external_link: true,
  },
  {
    name: 'about-us',
    path: '/about-us'
  },
  {
    name: 'careers',
    path: '/careers'
  },
]