<template>
  <div class="sg-footer">
    <div class="container is-max-widescreen">

      <div class="columns is-mobile">
        <div class="column is-6-tablet is-12-mobile has-text-centered-mobile">
          <p class="mb-1">
            <a target="_blank"
               class="has-text-light is-flex is-align-items-center"
               href="https://wa.me/6285158724492?text=Halo, saya butuh bantuan panen tambak saya dengan sgara">
              <b-icon icon="whatsapp" class="mr-2"/>
              +62 851 5872 4492 (Anang)
            </a>
          </p>
          <p class="mb-1">
            <a class="has-text-light is-flex is-align-items-center" href="mailto:hello@sgara.id">
              <b-icon icon="email-outline" class="mr-2"/>
              hello@sgara.id
            </a>
          </p>
          <p class="is-flex is-align-items-center">
            <b-icon icon="map-marker-outline" class="mr-2"/>
            Jl. Setia No. 17, Bekasi 17116
          </p>
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <div class="sg-footer-nav">
            <router-link to="/#solutions">{{ $t('navbar.solutions') }}</router-link>
            <router-link to="/about-us">{{ $t('navbar.about-us') }}</router-link>
            <router-link to="/careers">{{ $t('navbar.careers') }}</router-link>
          </div>
        </div>
      </div>

      <div class="is-divider"></div>

      <div class="columns is-mobile mt-2">
        <div class="column is-6-tablet is-12-mobile has-text-centered-mobile">
          <p>&copy; {{ year }} PT. Transformasi Digital Laut</p>
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <div class="social">
            <a href="https://www.instagram.com/sgara.id/" target="_blank">
              <b-icon icon="instagram"></b-icon>
            </a>
            <a href="https://id.linkedin.com/company/sgaraid/" target="_blank">
              <b-icon icon="linkedin"></b-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SgFooter",
  computed: {
    year() {
      return new Date().getUTCFullYear()
    }
  },
}
</script>

<style lang="scss" scoped>
.sg-footer {
  //background-image: linear-gradient(to right, #1988D2, #819EFF);
  background-color: #11324d;
  color: #FFFFFF;
  padding: 50px 0;

  .content-title {
    color: #FFFFFF;
    font-size: 1.6rem;
  }

  .sg-footer-nav {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;

    > a {
      color: rgba(255, 255, 255, .8);
      margin-left: 2rem;

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }

    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }

  .social {
    display: flex;
    justify-content: flex-end;
    height: 100%;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    > a {
      color: rgba(255, 255, 255, .8);
      margin-left: 1rem;

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
</style>